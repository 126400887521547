.appContainer {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.logo {
  margin-top: 20px;
}

.divider img {
  width: 100%;
  max-width: 500px;
  margin: 20px 0;
}
.Divider {
  color: red;
  width: 90%;
}
.separator {
  font-size: 20px;
  color: lightgray;
  margin-top: -2px;
}
.mainText {
  font-size: 34px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
}

.mainText h1 {
  margin: 0;
  font-weight: 500;
}




@media (min-width: 600px) {
  .buttons {
    flex-direction: row;
  }
}


.Get {
  font-size: 10px;
}
.download {
  font-size: 10px;
}
.footer {
  width: 100vw;
  height: 48px;
  background-color: #6aa84f;
  color:#FFFFFF;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footerLinks {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

h2 {
  color: black;
  font-size: 26px;
  margin: 10px 0 0 10px;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  animation: animtext 4s steps(120, end);
  transition: all cubic-bezier(0.1, 0.7, 1, 0.1);
}
@keyframes animtext {
  from {
    width: 0;
    transition: all 2s ease-in-out;
  }
}




/* Yatendra Styles */
.buttons {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}
.section{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.textHeadings{
  font-weight: 500;
}
.appStore,
.googlePlay {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #000000;
  color: #FFFFFF;
  height: 48px;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.appStore:hover,
.googlePlay:hover {
  background-color: #333;
  text-decoration: none;
}

.appStore img,
.googlePlay img {
  width: 20px;
  height: 23px;
}
.storeName {
  font-size: 16px;
  text-align: left;
}
.link {
  color: #FFFFFF;
  font-weight: 500;
}
.link:hover{
  text-decoration: none;
}
@media (min-width: 600px) {
  .textHeadings{
    font-size: 66px;
  }
  .appStore,.googlePlay{
    width: 180px;
  }
  .downloadText {
  font-size: 24px;
}
  .footer {
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    padding: 0px 80px;
  }
  .link{
    font-size: 16px;
  }
  @media (max-width:902px) {
    .footer{
      padding: 0px 40px;
    }
  }
  @media (max-width:822px) {
    .footer{
      height: 80px;
      padding: 0px 40px;
    }
  }
}
@media (max-width: 599px) {
  .textHeadings{
    font-size: 56px;
  }
  .appStore,.googlePlay{
    width: 164px;
  }
  .downloadText {
  font-size: 16px;
}
  .footer {
    min-height: 80px;
    gap: 16px;
  }
  .link{
    font-size: 14px;
  }
}
