.termsContainer {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  color: #333;
}

.termsHeader {
  text-align: center;
}

.logo {
  width: 80px;
}

.termsHeader h1 {
  font-size: 24px;
  margin: 10px 0;
}

.termsHeader h2 {
  font-size: 18px;
  color: #666;
}

.termsIntro {
  margin: 20px 0;
  font-size: 14px;
}

.termsSection {
  margin-top: 15px;
}

.termsSection p {
  font-size: 14px;
  line-height: 1.6;
}

a {
  color: #0073e6;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
.copyright{
  display: flex;
  gap: 5px;
  align-items: center;
  font-size:14px;
}