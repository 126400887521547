.contactContainer {
  color: #333;
  min-height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  /* padding: 1rem; */
}

/* .contactHeader img {
  max-width: 150px;
  width: 100%;
} */

.divider img {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.statement {
  color: #555;
  font-size: 18px;
  font-weight: 400;
}
.heading {
  box-sizing: border-box;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}
.contactOption {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.separator {
  font-size: 20px;
  color: lightgray;
  margin-top: -2px;
}
.contactMain{
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.contactBox {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  width: 90%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.contactFooter {
  margin-top: 2rem;
  padding: 1rem;
  background-color: #6aa84f;
  color: white;
  font-size: 0.875rem;
}

.contactFooter a {
  color: white;
  margin: 0 0.5rem;
  text-decoration: none;
}

.contactFooter a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .contactOption {
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .contactMain h2 {
    font-size: 1.25rem;
  }

  .contactFooter {
    font-size: 15px;
  }
}
.footer {
  width: 100%;
  min-height: 48px;
  background-color: #6aa84f;
  color: white;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40%;
  justify-content: center;
}
.link {
  color: white;
  text-decoration: none;
}
.footerLinks {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 600px) {
  .footer {
    flex-direction: row;
    justify-content: space-around;
  }
}
@media (max-width: 599px) {
  .footer {
    min-height: 80px;
  }
}
